import React, {useState, useEffect }  from "react";
import sanityClient from "../client.js";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";

export default function Navbar() {
  const [socialData, setSocials] = useState(null);
  const [settingsData, setSettings] = useState(null);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "social"] | order(sortNum) {
      title,
      link,
      sortNum,
      }`)
      .then((data) => setSocials(data))
      .catch(console.error);
  }, [] );
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      logo{
        asset->{
          url
        }
      }
      }`)
      .then((data_settings) => setSettings(data_settings))
      .catch(console.error);
  }, [] );

  function scrollToTop() {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  }
  
  return (
    <div class="navbar" id="navbar">
    <a class="nav-left"><img onClick={scrollToTop} src={settingsData && settingsData.logo.asset.url + '?h=122'}/></a>
    <div class="nav-right">
      {/* {socialData && socialData.map((social, index) => (
        <div class="nav-item"><SocialIcon key={index} url={social.link} className="" target="_blank" fgColor="#000" bgColor="transparent" style={{height: 35, width: 35}}></SocialIcon></div>
      ))} */}
      <div class="nav-item"><a href="https://www.youtube.com/channel/UClJa59bQiOVLgM23TKQXc4g/featured" target="_blank"><i class="fab fa-youtube"></i></a></div>
      <div class="nav-item"><a href="https://www.linkedin.com/in/sean-roper-889aa1158/" target="_blank"><i class="fab fa-linkedin"></i></a></div>
      <div class="nav-item"><a href="https://instagram.com/seanroper430" target="_blank"><i class="fab fa-instagram"></i></a></div>
      <div class="nav-item"><a href="mailto:SeanRoperPhotoVideo@gmail.com" target="_blank"><i class="fa fa-envelope"></i></a></div>
    </div>
  </div>
  )
}