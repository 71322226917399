import React, {useState, useEffect, useRef }  from "react";
import sanityClient from "../client.js";

export default function Header() {

  const [settingsData, setSettings] = useState(null);

  // Settings GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      title
      }`)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, [] );
  
  return (
    <div>
      <title>{settingsData && settingsData.title}</title>
      <meta name="description" content="Professional video/photographer with over 3 years of industry experience." />
      <meta name="keywords" content="" />
    </div>
  )
}