import React, {useState, useEffect, useRef }  from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function Contact() {

  const [settingsData, setSettings] = useState(null);
  const emilyRef = useRef()

  // Settings GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      contactWriteup
      }`)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, [] );

  function Emily()
  {
    try { 
      emilyRef.current.removeAttribute('required');
      emilyRef.current.setAttribute('autocomplete','off')
    } catch {;}
  }
  
  // setTimeout(Emily,5000);
  
  return (
    <div class="content ctnr-work">
      <div class="ctnr">
        <h1>Work With Me</h1>

        <BlockContent blocks={settingsData && settingsData.contactWriteup} projectId="b3dsr3ll" dataset="production"/>

        <div class="form-container">
          <form method="post" name="form-contact" action="./php/submit.php">
            <input placeholder="Name" type="text" name="name" class="forms input" required/>
            {/* <input style={{display:'none'}} ref={emilyRef}placeholder="Email" type="email" name="email" class="forms input" required/> */}
            <input placeholder="Email" type="email" name="emily" class="forms input" required/>
            <textarea placeholder="Message" name="message" class="forms input" required></textarea>
            <div class="btn"><input class="submit" type="submit" name="submit" value="Submit"/></div>
          </form>
        </div>
        
      </div>
    </div>
  )
}