import React, {useState, useEffect}  from "react";
import sanityClient from "../client.js";
import { useMainContext } from './MainContext';

export default function Projects({plyrEmbed, photoOverlay}) {
  
  const { SetMuteButton, SetCurImages, isModalVisible, setIsModalVisible, selectedImageIndex, setSelectedImageIndex, SetModalPosition } = useMainContext();
  const [videoData, setVideos] = useState(null);
  const [galleryData, setGallery] = useState(null);
  
  var plyr_done = false;
  var lastSrc = "";

  // Modal functions
  const ShowVideoModal = (src, provider) => {
    let p = document.getElementById('player');

    document.getElementById('overlay').style.display = "block";

    SetMuteButton(true,false);
    DisableNavbar();

    // Try to remove silly extra controls from plyrs
    try {
      if(!plyr_done)
        {
          document.querySelectorAll('[data-plyr="pip"]').forEach((e) => e.remove());
          document.querySelectorAll(".plyr__menu").forEach((e) => e.remove());
          plyr_done = true;
        }
      }
      catch {;}
      
    // If it's HTML5 video
    if(provider == '')
    {
      p.style.display = "block";
      document.querySelectorAll('.plyr--video').forEach((e) => {
        if(!e.classList.contains('plyr--html5'))
          e.style.display = 'none';
      });

      if(p.src != src)
      {
        p.src = src;
        p.play();
      }

      p.play();
    }
    // If it's YouTube or Vimeo
    else
    {
      p.style.display = "none";
      document.querySelectorAll('.plyr--video').forEach((e) => {
        if(!e.classList.contains('plyr--html5'))
          e.style.display = 'block';
      });

      if(lastSrc != src)
        {
          plyrEmbed.current.plyr.source = { type: 'video', sources: [{ src: src, provider:provider }] };
          lastSrc = src;
        }
        plyrEmbed.current.plyr.play();
    }

    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    SetModalPosition();
  };
  function ShowPhotoModal(src)
  {
    if(src != lastGallery)
        setSelectedImageIndex(0);
      
    SetCurImages(src);
    SetMuteButton(true,false);
    DisableNavbar();
    setIsModalVisible(true);
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';

    lastGallery = src;

    // check to see if it's the same one as before and reset thumbnail / selectedImage number if so so it doesnt go out of bounds
    // lastGallery really isn't helping rn it's just resetting every time no matter what
  }

  useEffect(() => {
    if (photoOverlay.current) {
      if (isModalVisible) {
        photoOverlay.current.style.display = "block";
      } else {
        photoOverlay.current.style.display = "none";
      }
    }
  }, [isModalVisible]);

  function DisableNavbar()
  {
    let nv = document.getElementById('navbar');
    nv.style.opacity = "0";
    nv.style.transform = "translateY(-100px)";
  }

  // Video GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "video"] | order(rank) {
      title,
      role,
      link,
      thumbnail {asset->{url}},
      video {asset->{url}}
      }`)
      .then((data_videos) => setVideos(data_videos))
      .catch(console.error);
  }, [] );

  // Photo GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "gallery"] | order(rank) {
      title,
      thumbnail {asset->{url}},
      photos[] {asset->{url}}
      }`)
      .then((data_gallery) => setGallery(data_gallery))
      .catch(console.error);
  }, [] );

  var lastGallery = '';

  return (
    <div class="content"> <span id="photovideo"><span id="photo"><span id="video"></span></span></span>
      <div class="ctnr work" id="work">
        <h1>My Work</h1>
        <h3><a id="work-header-video" class="work-header work-header-active" href="#video">Video</a>&nbsp;&nbsp;&nbsp;&nbsp;<a id="work-header-photo" class="work-header" href="#photo">Photo</a></h3>

        <div id="video-grid" class="grid-container videos">
          <div class="project-grid">
            {videoData && videoData.map((video, index_video) => (
              <div class="project-item">
                <div class="thumbnail-container">
                  <img onClick={() => ShowVideoModal(
                    video.link ? video.link : video.video.asset.url, 
                    video.link ? video.link.includes("yout") ? 'youtube' : video.link.includes("vimeo") ? 'vimeo' : '' : ''
                  )} 
                  src={video.thumbnail && video.thumbnail.asset.url + '?w=500'} alt={video && video.title}/>
                </div>
                <p class="project-title">{video && video.title}</p>
                <p class="project-role">{video && video.role}</p>
              </div>
            ))}
          </div>
        </div>
        
        <div id="photo-grid" class="grid-container photos hidden">
          <div class="project-grid">
            {galleryData && galleryData.map((gallery, index_gallery) => (
              <div class="project-item">
                <div className="thumbnail-container thumbnail-ctnr-photos">
                  <img onClick={() => ShowPhotoModal(gallery.photos)} src={gallery.thumbnail.asset.url + '?w=500'} alt={gallery && gallery.title}/>
                </div>
                <p class="project-title">{gallery && gallery.title}</p>
                {/* <p class="project-role">{gallery && gallery.title}</p> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}