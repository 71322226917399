import React, {useState, useEffect }  from "react";
import sanityClient from "../client.js";
import Plyr from "plyr";
import { useMainContext } from './MainContext';

export default function Landing() {

  const { SetMuteButton, ToggleMute, muteClass } = useMainContext();

  // Settings constant
  const [settingsData, setSettings] = useState(null);


  // Settings GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      landingVideo{ asset->{url} },
      landingHeader,
      landingSubtext
      }`)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, [] );

  return (
    <div class="splash-ctnr">
      <div class="splash-txt-ctnr">
        <div class="splash-txt">{settingsData && settingsData.landingHeader}</div>
        <div class="splash-txt-small">{settingsData && settingsData.landingSubtext}</div>
        <a href="#work"><div class="btn">View my work</div></a>
      </div>
      <div class="down-arrow" id='down-arrow'>
        <a href="#about">
          <i class="fa fa-arrow-down"></i>
        </a>
      </div>
      
      <i className={"mute-btn fas fa-volume-" + muteClass} onClick={ToggleMute} style={{ cursor: 'pointer' }}></i>
      <video src={settingsData && settingsData.landingVideo.asset.url} id="header-video" muted autoPlay loop loading="lazy"/>
      
    </div>
  )
}