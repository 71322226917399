import React, {useState, useEffect, useRef }  from "react";
import sanityClient from "./client.js";

import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ModalVideoOverlay from "./components/ModalVideoOverlay";
import ModalPhotoOverlay from "./components/ModalPhotoOverlay";
import ModalResumeOverlay from "./components/ModalResumeOverlay";

import { MainProvider } from './components/MainContext';

function App() {

  // Variables constant
  const [settingsData, setSettings] = useState(null);
  const plyrEmbed = useRef();
  const photoOverlay = useRef();

  var videoGridEnabled = true;

  // Set favicon to settings' favicon
  document.querySelector("link[rel*='icon']").href = settingsData && settingsData.favicon.asset.url;

  // Settings GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      favicon{
        asset->{
          url
        }  
      }
      }`)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, [] );

  // Hash stuff
  function HandleHashChange()
  {
    try
    {
      var workHeaderVideo = document.getElementById("work-header-video");
      var workHeaderPhoto = document.getElementById("work-header-photo");
      var videoGrid = document.getElementById("video-grid");
      var photoGrid = document.getElementById("photo-grid");

      let h = window.location.hash;
    
      if(h.includes("video"))
      {
        SwitchActiveHeader(workHeaderPhoto, workHeaderVideo, videoGrid, photoGrid, true);
      }
      else if(h.includes("photo"))
      {
        SwitchActiveHeader(workHeaderVideo, workHeaderPhoto, videoGrid, photoGrid, false);
      }
    } 
    catch {;}
  }
  function SwitchActiveHeader(inactiveHeader, activeHeader, _videoGrid, _photoGrid, _videoGridEnabled)
  {
    try 
    {
      if(inactiveHeader.classList.contains("work-header-active"))
        inactiveHeader.classList.remove("work-header-active");
      if(!activeHeader.classList.contains("work-header-active"))
        activeHeader.classList.add("work-header-active");

      videoGridEnabled = _videoGridEnabled;

      if(videoGridEnabled)
      {
        if(_videoGrid.classList.contains("hidden"))
        {
          _videoGrid.classList.remove("hidden");
          if(!_photoGrid.classList.contains("hidden"))
            _photoGrid.classList.add("hidden");
        }
      }
      else
      {
        if(_photoGrid.classList.contains("hidden"))
        {
          _photoGrid.classList.remove("hidden");
          if(!_videoGrid.classList.contains("hidden"))
            _videoGrid.classList.add("hidden");
        }
      }
    } 
    catch {;}
  }
  useEffect(() => {
    const handleHashChange = () => {
      HandleHashChange();
    };

    window.addEventListener('hashchange', handleHashChange);

    // Clean up the event listener
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []); 
  
  HandleHashChange();
  setTimeout(HandleHashChange,2000);
  // End hash stuff
  
  // Handle orientation (add try catch later)
  document.addEventListener("fullscreenchange", function() 
  {
    console.log(document.fullscreenEnabled);
    
    let switchToPortrait = true;

    if(document.fullscreenElement == null)
    {
      lock('portrait');
    }
    if(document.fullscreenElement != null)
    {
      if(document.fullscreenElement.classList.contains("plyr"))
      {
        console.log(document.fullscreenElement);
        
        if(document.fullscreenEnabled)
        {
          lock('landscape');
          switchToPortrait = false;
        }
        else
        {
          lock('portrait');
        }
      }
    }
    if(switchToPortrait)
        lock('portrait');
  });
  function lock (orientation) {
    window.screen.orientation.lock(orientation);
  }
  function unlock () {
    window.screen.orientation.unlock();
  }

  return (
    <MainProvider>
      <Header />
      <Navbar />
      <Landing />
      <About />
      <Projects plyrEmbed={plyrEmbed} photoOverlay={photoOverlay}/>
      <Contact />
      <Footer />
      <ModalVideoOverlay plyrEmbed={plyrEmbed}/>
      <ModalPhotoOverlay photoOverlay={photoOverlay} />
      {/* <ModalResumeOverlay /> */}
    </MainProvider>
  )
}

export default App;