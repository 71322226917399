import React from "react";

export default function Footer() {
  return (
    <div class="ctnr-footer">&copy; {new Date().getFullYear()} Sean Roper
      <br/>
      <span class="footer-2">
        Design by <a href="http://www.stephenchildree.com" target="_blank">Stephen Childree</a>
      </span>
    </div>
  )
}