import React, {useState, useEffect, useRef, useCallback }  from "react";
import { useMainContext } from './MainContext';
import sanityClient from "../client.js";

export default function ModalPhotoOverlay({photoOverlay}) {

  const { ReturnToPrevMuteState, curImages, isModalVisible, setIsModalVisible, selectedImageIndex, setSelectedImageIndex} = useMainContext();

  // Modal-related functions
  const CloseModal = () => {
    setIsModalVisible(false);

    ReturnToPrevMuteState();
    
    document.getElementById("navbar").style.opacity = "1";
    document.getElementById("navbar").style.transform = "translateY(0)";

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
  function TryCloseModal(e) {
    if(e.target.id == "overlay-photo-2")
      CloseModal();
  }
  // End modal-related functions

  // Gallery thumbnails
    const nextImage = () => {
      SelectImage((prevIndex) => (prevIndex === curImages.length - 1 ? 0 : prevIndex + 1));
    };
    const prevImage = () => {
      SelectImage((prevIndex) => (prevIndex === 0 ? curImages.length - 1 : prevIndex - 1));
    };
    const SelectImage = (index) => {
      setSelectedImageIndex(index);
    };

    useEffect(() => {
      ScrollToCurrentImage(selectedImageIndex);
    },[selectedImageIndex]);

  function ScrollToCurrentImage(i)
  {
    // Update to use refs eventually
    try {
      let thumbnails = document.getElementsByClassName('thumbnails-ctnr');
      let curSelected = document.getElementsByClassName('thumbnails-selected')[0];
      let gallery = document.getElementById('thumbnails-bar');

      let thumbnail = thumbnails[0]
      let style = window.getComputedStyle ? getComputedStyle(thumbnail, null) : thumbnail.currentStyle;
      let marginRight = parseInt(style.marginRight) || 0;

      gallery.scrollTo({
        top:0,
        left:(thumbnail.offsetWidth + marginRight) * (i),
        behavior:"smooth"
      });
    } catch {;}
  }

  // Arrow keys
  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'ArrowLeft' && isModalVisible) {
          prevImage();
        }
        else if (event.key === 'ArrowRight' && isModalVisible) {
          nextImage();
        }
      }

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalVisible]);

  try {
    return (
      <div id="overlay-photo" ref={photoOverlay} onClick={TryCloseModal} className="modal-overlay">
        <div id="overlay-photo-2">
          <div id="photo-ctnr">

            <div className="img-ctnr">
              <img src={curImages[selectedImageIndex].asset.url} id="photograph" alt={`Image ${selectedImageIndex}`} />
              {/* <div id='controls' ref={resizableRef}
                style={{
                  width: `${dimensions.width}px`,
                  height: `${dimensions.height}px`,
                }}> */}
              <div id="photo-controls">
                <div className="arrow left-arrow" id='left-arrow' onClick={prevImage}><i className="fa-solid fa-chevron-left"></i></div>
                <div className="arrow right-arrow" id='right-arrow' onClick={nextImage}><i className="fa-solid fa-chevron-right"></i></div>
                <div className="modal-x" onClick={CloseModal}><i className="fa-regular fa-circle-xmark"></i></div>
              </div>
            </div>
            
            <div className="modal-gallery" id='modal-gallery' tabIndex="0">
              <div className="thumbnails-bar" id="thumbnails-bar">
                {curImages.map((image, index) => (
                  <div className={`${index < curImages.length - 1 ? 'thumbnails-ctnr thumbnails-padded' : 'thumbnails-ctnr'}`} >
                    <div className={index === selectedImageIndex ? `thumbnails-holder thumbnails-selected` : `thumbnails-holder`}>
                      <img key={index} src={image.asset.url + '?w=120'} alt={`Thumbnail ${index}`}
                      onClick={() => SelectImage(index)} className="thumbnails"/>
                    </div> 
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
  catch {;}
}