import React, { createContext, useContext, useState , useRef} from 'react';
import Plyr from "plyr";

// Set context stuff
const MainContext = createContext();
export const useMainContext = () => useContext(MainContext);
export const MainProvider = ({ children }) => {
  
  const plyr = new Plyr('#player');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // State to track the index of the selected image

  const [curImages, setCurImages] = useState(null);
  function SetCurImages(src)
  {
    setCurImages(src);
  }
  // Mute stuff
  const [muteClass, setMuteClass] = useState('mute');
  const [muteState, setMuteState] = useState(true);

  const ToggleMute = () => {
    SetMuteButton(!document.getElementById("header-video").muted, true)
  };

  function SetMuteButton(state, updateState)
  {
    let muteBtn = document.getElementById("header-video");
    muteBtn.muted = state;
    SetMuteClass();

    if(updateState)
      setMuteState(state);
  }

  const ReturnToPrevMuteState = () => {
    SetMuteButton(muteState, true)
    SetMuteClass();
  };

  function SetMuteClass() {
    setMuteClass(document.getElementById("header-video").muted ? 'mute' : 'down');
  }
  // End mute stuff

  function SetModalPosition()
  {
    var modal = document.getElementById('modal');
    var imageHeight = modal.clientHeight;
    var vpHeight = document.documentElement.clientHeight;
    // modal.style.top = (vpHeight - imageHeight)/2 + window.innerHeight + 'px';
    // modal.style.top = ((window.innerHeight / 2 + (vpHeight / 2)) / 2) + (window.innerHeight / 16) + 'px';

    console.log((modal.style.top));
  }



  return (
    <MainContext.Provider value={{ 
      // Include global vars/functions  here
      ReturnToPrevMuteState,
      SetMuteButton,
      ToggleMute,
      muteClass,
      SetCurImages,
      curImages,
      isModalVisible,
      setIsModalVisible,
      selectedImageIndex,
      setSelectedImageIndex,
      SetModalPosition
    }}>
      {children}
    </MainContext.Provider>
  );
};