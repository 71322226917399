import React, {useState, useEffect }  from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

export default function About() {

  // Settings constant
  const [settingsData, setSettings] = useState(null);

  // Settings GROQ
  useEffect(() => {
    sanityClient.fetch(`*[_type == "siteSettings"][0] {
      aboutMeSummary,
      aboutMePhoto{ asset->{url}}
      }`)
      .then((data) => setSettings(data))
      .catch(console.error);
  }, [] );

  function OpenResume()
  {
    document.getElementById('overlay-pdf').style.display = "block";

    let nv = document.getElementById('navbar');
    nv.style.opacity = "0";
    nv.style.transform = "translateY(-100px)";

  }

  return (
    <div class="content" id="about">
      <div class="ctnr">
        <h1>About Me</h1>
        <div class="about">
          <img src={settingsData && settingsData.aboutMePhoto && settingsData.aboutMePhoto.asset.url + '?w=215'}/>
          <div class="mobile-only-breakpoint"></div>
            <BlockContent blocks={settingsData && settingsData.aboutMeSummary} projectId="b3dsr3ll" dataset="production"/>
        </div>
        {/* <a href="#resume"><div onClick={OpenResume} class="btn about-btn">View My Resume</div></a> */}
        <hr/>
      </div>
    </div>
  )
}