import React from "react";
import Plyr from "plyr";
import { useMainContext } from './MainContext';

export default function ModalVideoOverlay({plyrEmbed}) {
  
  const { ReturnToPrevMuteState, SetModalPosition } = useMainContext();
  const PlyrEmbed = new Plyr(plyrEmbed.current,{});
  
  // Modal-related functions
  const CloseModal = () => {
    document.getElementById('overlay').style.display = "none";
    document.getElementById('player').pause();
    plyrEmbed.current.plyr.pause();

    ReturnToPrevMuteState();
    
    document.getElementById("navbar").style.opacity = "1";
    document.getElementById("navbar").style.transform = "translateY(0)";

    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }
  function TryCloseModal(e) {
    if(e.target.id == "overlay-2")
      CloseModal();
  }
  // End modal-related functions

  return (
    <div id="overlay" onClick={TryCloseModal}>
      <div id="overlay-2">
        <div id="modal">
          <div class="modal-x" onClick={CloseModal}><i class="fa-regular fa-circle-xmark"></i></div>
          <video src="" id="player" playsInline controls width="400px" />
          <div ref={plyrEmbed} id="player-embed" data-plyr-embed-id='https://www.youtube.com/' data-plyr-provider='youtube' />
        </div>
      </div>
    </div>
  )
}